.iframe-container-invisible {
    position: relative;
}

.frame {
    display: block;
    background: #000;
    border: none;
    height: 100vh;
    width: 100vw;
}

@media  (min-width: 300px) and (min-height: 508px){.frame {height: 90vh;}}/*iphone6 or smaller*/
@media  (min-width: 370px) and (min-height: 667px){.frame {height: 92vh;}}/*iphone6/7/8*/
@media  (min-width: 375px) and (min-height: 812px){.frame {height: 80vh;}}/*iphonex*/
@media (min-width: 411px) and (min-height: 600px){.frame {height: 89vh;}}
@media (min-width: 411px) and (min-height: 731px) {.frame {height: 91vh;}} /*pixel2*/
@media (min-width: 411px) and (min-height: 823px){.frame {height: 79vh;}} /*pixel2 pro*/
@media (min-width: 414px) and (min-height: 736px){.frame {height: 88vh;}}/*iphone6/7/8 plus*/
@media (min-width: 494px) and (min-height: 536px){.frame {height: 100vh;}}/*for broswer in maintenance*/
@media (min-width: 768px) and (min-height: 1024px){.frame {height: 63vh;}}/*ipad*/
@media (min-width: 900px) and (min-height: 630px){.frame {height: 100vh;}}/*running local on broswer*/
@media (min-width: 1024px) and (min-height: 1366px){.frame {height: 47vh;}}/*ipad pro*/

.frame-invisible {
    width: 0;
    height: 0;
    border: 0;
    border: none;
    display: none;
    visibility: hidden;
}

.draggable-contents {
    border: 1px solid black;
    width: 200px;
    padding-top: 10px;
}

.btn-group {
    padding-top: 10px;
}

.clickable {
    cursor: pointer;
}
